import Cmdetector from 'assets/icons/amenities/CM_detector.svg';
import Aircondition from 'assets/icons/amenities/air_condition.svg';
import Breakfast from 'assets/icons/amenities/breakfast.svg';
import Buzzer from 'assets/icons/amenities/buzzer.svg';
import Checkin from 'assets/icons/amenities/checkin.svg';
import Doorman from 'assets/icons/amenities/doorman.svg';
import Dryer from 'assets/icons/amenities/dryer.svg';
import Elevator from 'assets/icons/amenities/elevator.svg';
import Essentials from 'assets/icons/amenities/essentials.svg';
import Events from 'assets/icons/amenities/events.svg';
import Familyfriendly from 'assets/icons/amenities/family_friendly.svg';
import Fireextinguisher from 'assets/icons/amenities/fire_extinguisher.svg';
import Fireplace from 'assets/icons/amenities/fireplace.svg';
import Firstaid from 'assets/icons/amenities/first_aid.svg';
import Gym from 'assets/icons/amenities/gym.svg';
import Hairdryer from 'assets/icons/amenities/hair_dryer.svg';
import Hangers from 'assets/icons/amenities/hangers.svg';
import Heating from 'assets/icons/amenities/heating.svg';
import Hottub from 'assets/icons/amenities/hot_tub.svg';
import Iron from 'assets/icons/amenities/iron.svg';
import Keypad from 'assets/icons/amenities/keypad.svg';
import Kitchen from 'assets/icons/amenities/kitchen.svg';
import Laptop from 'assets/icons/amenities/laptop.svg';
import Lockbox from 'assets/icons/amenities/lockbox.svg';
import Locker from 'assets/icons/amenities/locker.svg';
import Parking from 'assets/icons/amenities/parking.svg';
import Petsallowed from 'assets/icons/amenities/pets_allowed.svg';
import Petslive from 'assets/icons/amenities/pets_live.svg';
import Pool from 'assets/icons/amenities/pool.svg';
import Privateentrance from 'assets/icons/amenities/private_entrance.svg';
import Privatelivingroom from 'assets/icons/amenities/private_livingroom.svg';
import Safetycard from 'assets/icons/amenities/safety_card.svg';
import Shampoo from 'assets/icons/amenities/shampoo.svg';
import Smartlock from 'assets/icons/amenities/smartlock.svg';
import Smokedetector from 'assets/icons/amenities/smoke_detector.svg';
import Smoking from 'assets/icons/amenities/smoking.svg';
import Tv from 'assets/icons/amenities/tv.svg';
import Washer from 'assets/icons/amenities/washer.svg';
import Wheelchair from 'assets/icons/amenities/wheelchair.svg';
import Wifi from 'assets/icons/amenities/wifi.svg';
import Cats from 'assets/icons/amenities/cats.svg';
import Dogs from 'assets/icons/amenities/dogs.svg';
import Otherpets from 'assets/icons/amenities/other_pets.svg';
import Internet from 'assets/icons/amenities/internet.svg';
import Accessibility from 'assets/icons/amenities/accessibility.svg';
import Baby from 'assets/icons/amenities/baby.svg';
import Bath from 'assets/icons/amenities/bath.svg';
import Bbq from 'assets/icons/amenities/bbq.svg';
import Beach from 'assets/icons/amenities/beach.svg';
import Bed from 'assets/icons/amenities/bed.svg';
import Chair from 'assets/icons/amenities/chair.svg';
import Cleaning from 'assets/icons/amenities/cleaning.svg';
import Coffee from 'assets/icons/amenities/coffee.svg';
import Console from 'assets/icons/amenities/console.svg';
import Dishes from 'assets/icons/amenities/dishes.svg';
import Dishwasher from 'assets/icons/amenities/dishwasher.svg';
import Ev from 'assets/icons/amenities/ev.svg';
import Garden from 'assets/icons/amenities/garden.svg';
import Guard from 'assets/icons/amenities/guard.svg';
import Hotwater from 'assets/icons/amenities/hot_water.svg';
import Longterm from 'assets/icons/amenities/long_term.svg';
import Luggage from 'assets/icons/amenities/luggage.svg';
import Microwave from 'assets/icons/amenities/microwave.svg';
import Oven from 'assets/icons/amenities/oven.svg';
import Patio from 'assets/icons/amenities/patio.svg';
import Refrigerator from 'assets/icons/amenities/refrigerator.svg';
import Shades from 'assets/icons/amenities/shades.svg';
import Stove from 'assets/icons/amenities/stove.svg';
import Table from 'assets/icons/amenities/table.svg';

import ListingGuests from 'assets/icons/hotelFeatures/listing_guests.svg';
import ListingBedrooms from 'assets/icons/hotelFeatures/listing_bedrooms.svg';
import ListingBeds from 'assets/icons/hotelFeatures/listing_beds.svg';
import ListingBathroom from 'assets/icons/hotelFeatures/listing_bathroom.svg';

import Star from 'assets/icons/star.svg';
import StarFilled from 'assets/icons/star_filled.svg';
import Close from 'assets/icons/close.svg';
import Coupon from 'assets/icons/coupon.svg';
import Location from 'assets/icons/Location.svg';
import Lock from 'assets/icons/lock.svg';
import Arrow from 'assets/icons/arrow.svg';
import ArrowNext from 'assets/icons/arrow_next.svg';
import ArrowDown from 'assets/icons/arrow_down.svg';
import ArrowRight from 'assets/icons/arrow_right.svg';
import Guest from 'assets/icons/guest.svg';
import Guests from 'assets/icons/guests.svg';
import Hotel from 'assets/icons/hotel.svg';
import InquirySuccess from 'assets/icons/inquiry_success.svg';
import InstantSuccess from 'assets/icons/instant_success.svg';
import NoResults from 'assets/icons/no_results.svg';
import NotFound from 'assets/icons/not_found.svg';
import Info from 'assets/icons/info.svg';
import ZeroAmountNotification from 'assets/baners/zeroAmountNotification.svg';

import PetsAllowed from 'assets/icons/houseRules/petsAllowed.svg'
import QuietBetween from 'assets/icons/houseRules/quietBetween.svg'
import SmokingAllowed from 'assets/icons/houseRules/smokingAllowed.svg'
import SuitableForEvents from 'assets/icons/houseRules/suitableForEvents.svg'
import SuitableForChildren from 'assets/icons/houseRules/suitableForChildren.svg'
import SuitableForInfants from 'assets/icons/houseRules/suitableForInfants.svg'
import NotPetsAllowed from 'assets/icons/houseRules/notPetsAllowed.svg'
import NotQuietBetween from 'assets/icons/houseRules/notQuietBetween.svg'
import NotSmokingAllowed from 'assets/icons/houseRules/notSmokingAllowed.svg'
import NotSuitableForEvents from 'assets/icons/houseRules/notSuitableForEvents.svg'
import NotSuitableForChildren from 'assets/icons/houseRules/notSuitableForChildren.svg'
import NotSuitableForInfants from 'assets/icons/houseRules/notSuitableForInfants.svg'

import Facebook from 'assets/icons/facebook.svg';
import Instagram from 'assets/icons/instagram.svg';
import Twitter from 'assets/icons/twitter.svg';
import Youtube from 'assets/icons/youtube.svg';

import Visa from 'assets/icons/visa.svg';
import Mastercard from 'assets/icons/mastercard.svg';
import Jcb from 'assets/icons/jcb.svg';
import Diners from 'assets/icons/diners.svg';
import Unionpay from 'assets/icons/unionpay.svg';
import Discover from 'assets/icons/discover.svg';
import Amex from 'assets/icons/amex.svg';

const AMENITIES = {
  CM_detector: Cmdetector,
  air_condition: Aircondition,
  breakfast: Breakfast,
  buzzer: Buzzer,
  checkin: Checkin,
  doorman: Doorman,
  dryer: Dryer,
  elevator: Elevator,
  essentials: Essentials,
  events: Events,
  family_friendly: Familyfriendly,
  fire_extinguisher: Fireextinguisher,
  fireplace: Fireplace,
  first_aid: Firstaid,
  gym: Gym,
  hair_dryer: Hairdryer,
  hangers: Hangers,
  heating: Heating,
  hot_tub: Hottub,
  iron: Iron,
  keypad: Keypad,
  kitchen: Kitchen,
  laptop: Laptop,
  lockbox: Lockbox,
  locker: Locker,
  parking: Parking,
  pets_allowed: Petsallowed,
  pets_live: Petslive,
  pool: Pool,
  private_entrance: Privateentrance,
  private_livingroom: Privatelivingroom,
  safety_card: Safetycard,
  shampoo: Shampoo,
  smartlock: Smartlock,
  smoke_detector: Smokedetector,
  smoking: Smoking,
  tv: Tv,
  washer: Washer,
  wheelchair: Wheelchair,
  wifi: Wifi,
  cats: Cats,
  dogs: Dogs,
  other_pets: Otherpets,
  internet: Internet,
  accessibility: Accessibility,
  baby: Baby,
  bath: Bath,
  bbq: Bbq,
  beach: Beach,
  bed: Bed,
  chair: Chair,
  cleaning: Cleaning,
  coffee: Coffee,
  console: Console,
  dishes: Dishes,
  dishwasher: Dishwasher,
  ev: Ev,
  garden: Garden,
  guard: Guard,
  hot_water: Hotwater,
  long_term: Longterm,
  luggage: Luggage,
  microwave: Microwave,
  oven: Oven,
  patio: Patio,
  refrigerator: Refrigerator,
  shades: Shades,
  stove: Stove,
  table: Table,
};

const HOTEL_FEATURES = {
  listing_guests: ListingGuests,
  listing_bedrooms: ListingBedrooms,
  listing_beds: ListingBeds,
  listing_bathroom: ListingBathroom,
};

const COMMON = {
  arrow_right: ArrowRight,
  arrow_down: ArrowDown,
  arrow_next: ArrowNext,
  arrow: Arrow,
  close: Close,
  coupon: Coupon,
  guest: Guest,
  guests: Guests,
  hotel: Hotel,
  info: Info,
  inquiry_success: InquirySuccess,
  instant_success: InstantSuccess,
  location: Location,
  lock: Lock,
  no_results: NoResults,
  not_found: NotFound,
  star_filled: StarFilled,
  star: Star,
  zeroAmount: ZeroAmountNotification,
};

const HOUSE_RULES = {
  petsAllowed: PetsAllowed,
  quietBetween: QuietBetween,
  smokingAllowed: SmokingAllowed,
  suitableForEvents: SuitableForEvents,
  suitableForChildren: SuitableForChildren,
  suitableForInfants: SuitableForInfants,
  notPetsAllowed: NotPetsAllowed,
  notQuietBetween: NotQuietBetween,
  notSmokingAllowed: NotSmokingAllowed,
  notSuitableForEvents: NotSuitableForEvents,
  notSuitableForChildren: NotSuitableForChildren,
  notSuitableForInfants: NotSuitableForInfants,
};

const SOCIAL_MEDIA = {
  facebook: Facebook,
  instagram: Instagram,
  twitter: Twitter,
  youtube: Youtube,
};

const CREDIT_CARDS = {
  visa: Visa,
  mastercard: Mastercard,
  jcb: Jcb,
  diners: Diners,
  unionpay: Unionpay,
  discover: Discover,
  amex: Amex,
};

export const iconMap = {
  ...COMMON,
  ...HOTEL_FEATURES,
  ...AMENITIES,
  ...CREDIT_CARDS,
  ...SOCIAL_MEDIA,
  ...HOUSE_RULES,
};

export default iconMap;
